import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  Filter,
  ReferenceField,
} from 'react-admin'

const FlashCardsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search question" source="question" alwaysOn />
  </Filter>
)

const FlashCardList = props => (
  <List
    {...props}
    exporter={false}
    filters={<FlashCardsFilter />}
    filter={{ disabled: false }}
  >
    <Datagrid>
      <ReferenceField
        label="Category"
        source="categoryId"
        reference="Categories"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="question" />
      <TextField source="correctAnswers" />
      <TextField source="flags" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
)

export default FlashCardList
